import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Fopalis Fitness
			</title>
			<meta name={"description"} content={"Welcome to Fopalis Fitness, a gymnasium that is proud to encourage a strong, supportive and challenging environment."} />
			<meta property={"og:title"} content={"Home | Fopalis Fitness"} />
			<meta property={"og:description"} content={"Welcome to Fopalis Fitness, a gymnasium that is proud to encourage a strong, supportive and challenging environment."} />
			<meta property={"og:image"} content={"https://doliverjunkis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://doliverjunkis.com/img/1800951.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://doliverjunkis.com/img/1800951.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://doliverjunkis.com/img/1800951.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://doliverjunkis.com/img/1800951.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://doliverjunkis.com/img/1800951.png"} />
			<meta name={"msapplication-TileImage"} content={"https://doliverjunkis.com/img/1800951.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Fopalis Fitness
				</Text>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 400 28px/1.5 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 100px 0px 100px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					Welcome to Fopalis Fitness, a gym that prides itself on fostering a strong, supportive, and challenging environment. Designed for those who are serious about fitness, our gym is a place where dedication meets results, and every workout is an opportunity to surpass your limits.
				</Text>
				<Button
					margin="0px 0 40px 0px"
					padding="14px 35px 14px 35px"
					background="#3f24d8"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
				>
					Visit us
				</Button>
			</Box>
			<Image
				src="https://doliverjunkis.com/img/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-5">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 36px 00px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="30%"
					lg-width="100%"
					lg-margin="24px 0px 30px 0px"
					lg-order="1"
					md-text-align="left"
				>
					Why Choose Fopalis Fitness?
					<br />
					<br />
					Expert Guidance – Our experienced trainers are dedicated to providing personalized advice and training programs to help you reach peak performance.
					<br />
					Community of Motivation – Be part of a community that values strength, perseverance, and personal growth.
				</Text>
				<Box padding="0px 0px 40% 0px" max-height="400px" overflow="hidden">
					<Image src="https://doliverjunkis.com/img/3.jpg" object-fit="cover" display="block" width="100%" />
				</Box>
			</Box>
			<Box
				justify-content="center"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="45%"
				lg-width="100%"
				align-items="flex-start"
			>
				<Box
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					overflow-y="hidden"
					position="relative"
					padding="0px 0px 150% 0px"
					lg-padding="0px 0px 100% 0px"
				>
					<Image
						object-fit="cover"
						display="block"
						top={0}
						lg-right="0px"
						lg-width="100%"
						bottom={0}
						min-height="100%"
						lg-left="-1px"
						src="https://doliverjunkis.com/img/2.jpg"
						position="absolute"
						width="100%"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="55%"
				lg-order="1"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="100%" padding="0px 0px 40% 0px" max-height="400px">
					<Image src="https://doliverjunkis.com/img/4.jpg" object-fit="cover" display="block" width="100%" />
				</Box>
				<Text
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Unique features
					<br />
					<br />
					Specialized Workout Zones – Our facility includes specialized zones for weightlifting, functional training, and endurance workouts.
					<br />
					Nutrition and Wellness Consultation – We offer consultations for nutritional guidance and wellness advice to complement your fitness regime.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});